.action-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    margin-top: 10px;
}

.action-buttons-container.with-advanced {
    margin-top: 30px;
    border-top: 1px solid #eee;
}

.button-group {
    display: flex;
    gap: 15px;
    align-items: center;
}

.fade-appear,
.fade-enter {
    opacity: 0;
    transform: translateY(-100%);
}

.fade-appear-active,
.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 500ms, transform 500ms;
}

.advanced-filter-transition {
    overflow: hidden;
    max-height: 1000px;
    animation: showTransition 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

.row .advanced-filter-transition {
    margin: 0;
}

.advanced-filter-transition.hide {
    animation: hideTransition 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
}

@keyframes showTransition {
    from {
        max-height: 0;
    }

    to {
        max-height: 1000px;
    }
}

@keyframes hideTransition {
    from {
        max-height: 1000px;
    }

    to {
        max-height: 0;
    }
}